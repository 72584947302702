import { action, thunk, thunkOn } from 'easy-peasy'
import { toast } from 'react-toastify'

import { apiErrorHandler } from '../../Utilities/Error'
import { getUsers as getAuthUsers, postUser as postAuthUser, patchUser as patchAuthUser } from '../../API/Services/User'
import {
  getUsers as getFlexUsers,
  postUserAdmin as postFlexUser,
  patchUserAdmin as patchFlexUser,
  deleteUserAdmin as deleteFlexUser,
} from '../../API/Services/Flex'
import { getUsers as getTorrensUsers } from '../../API/Services/Torrens'

const Users = {
  user: null,
  users: null,
  usersLoading: false,
  userPageCount: 0,
  torrensUsers: null,
  actionSuccessful: false,

  setUser: action((state, payload) => {
    state.user = payload
  }),

  setUserByUsername: action((state, payload) => {
    const user = state.users.find((u) => u.username == payload)
    state.user = user
  }),

  openCreateUserLoader: action((state) => {
    state.createUserLoading = true
  }),

  closeCreateUserLoader: action((state) => {
    state.createUserLoading = false
  }),

  openPatchUserLoader: action((state) => {
    state.patchUserLoading = true
  }),

  closePatchUserLoader: action((state) => {
    state.patchUserLoading = false
  }),

  setUsers: action((state, payload) => {
    state.users = payload
  }),

  openUsersLoader: action((state) => {
    state.usersLoading = true
  }),

  closeUsersLoader: action((state) => {
    state.usersLoading = false
  }),

  setUserPageCount: action((state, payload) => {
    state.userPageCount = payload
  }),

  setTorrensUsers: action((state, payload) => {
    state.torrensUsers = payload
  }),

  setActionSuccessful: action((state, payload) => {
    state.actionSuccessful = payload
  }),

  createUser: thunk(async (actions, payload) => {
    actions.openCreateUserLoader()

    const { username, authPayload, flexPayload } = payload

    try {
      // run auth first, then flex if auth is successful
      let temporaryPassword = undefined
      if (authPayload) {
        // pass temporary password into flex post for welcome email
        const authResponse = await postAuthUser({ username, ...authPayload })
        temporaryPassword = authResponse.data.temporaryPassword
      }
      if (flexPayload) await postFlexUser({ username, ...flexPayload, temporaryPassword })
      // success message
      toast.success(`User created successfully!`)
      actions.setActionSuccessful(true)
    } catch (err) {
      apiErrorHandler(err)
    }

    actions.closeCreateUserLoader()
  }),

  patchUser: thunk(async (actions, payload) => {
    actions.openPatchUserLoader()

    const { username, authPayload, flexPayload, flexUserId } = payload

    try {
      // run auth first, then flex if auth is successful
      if (authPayload) await patchAuthUser(username, { ...authPayload })
      if (flexPayload) {
        // update a flex user if the user has a flex user id
        if (flexUserId !== undefined) await patchFlexUser(flexUserId, flexPayload)
        else await postFlexUser(flexPayload)
      }
      // delete a flex user if the user has a flex user id, but no flex payload was provided
      if (flexUserId && flexPayload == undefined) {
        await deleteFlexUser(flexUserId)
      }
      // success message
      toast.success(`User updated successfully!`)
      actions.setActionSuccessful(true)
    } catch (err) {
      apiErrorHandler(err)
    }

    actions.closePatchUserLoader()
  }),

  getUsers: thunk(async (actions, payload, { getStoreState }) => {
    actions.openUsersLoader()

    const partnerId = getStoreState().partner.selected?.value
    if (!partnerId) return

    try {
      const { pageSize, pageIndex, filter, sortBy, sortDirection } = payload ?? {}
      const [authUserResponse, torrensUsers] = await Promise.all([
        getAuthUsers(partnerId, pageIndex ?? 0, pageSize ?? 15, filter, sortBy, sortDirection),
        actions.getTorrensUsers(),
      ])
      const authUsers = authUserResponse.data.data
      const flexUsers = await getFlexUsers(authUsers.map((user) => user.username))

      const results = authUsers.map((authUser) => {
        const flexUser = flexUsers.find((user) => user.email === authUser.username)
        const torrensUser = torrensUsers.find((user) => user.email === authUser.username)

        return {
          ...authUser,
          email: flexUser?.email,
          role: flexUser?.role,
          flexUserId: flexUser?.id,
          access: {
            siteIds: flexUser?.access?.siteIds ?? [],
            buildingIds: flexUser?.access?.buildingIds ?? [],
          },
          flexBuildingCount: flexUser?.access.buildingCount,
          torrensRole: torrensUser?.role,
        }
      })

      actions.setUsers(results)
      actions.setUserPageCount(Math.ceil(authUserResponse.data.additionalRecords / pageSize) + pageIndex + 1)
    } catch (e) {
      apiErrorHandler(e)
    }

    actions.closeUsersLoader()
  }),

  getTorrensUsers: thunk(async (actions, refresh, { getStoreState }) => {
    let torrensUsers = getStoreState().users.torrensUsers

    if (refresh || torrensUsers == null) {
      const partnerId = getStoreState().partner.selected?.value
      const torrensUserResponse = await getTorrensUsers(partnerId)
      torrensUsers = torrensUserResponse.data

      actions.setTorrensUsers(torrensUsers)
    }

    return torrensUsers
  }),

  onSelectPartner: thunkOn(
    (_, storeActions) => storeActions.partner.setSelected,

    async (actions) => {
      await actions.getTorrensUsers(true)
    }
  ),
}

export default Users
