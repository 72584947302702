import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import PageHeader from '@components/PageHeader'
import { addUserActions, editUserActions } from './userActions'
import PersonalInformationCard from '@components/UserManagement/PersonalInformationCard'
import FlexAppConfigCard from '@components/UserManagement/FlexAppConfigCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import '@src/App.scss'

const UserManagement = (props) => {
  const [formLoading, setFormLoading] = useState(true)
  const submitHTML = useRef(null)
  const navigate = useNavigate()

  // GLOBAL STATE
  const defaultUserData = useStoreState((state) => state.users.user)
  const currentPartner = useStoreState((state) => state.partner.selected)
  const creatingUser = useStoreState((state) => state.users.createUserLoading)
  const createUser = useStoreActions((actions) => actions.users.createUser)
  const updatingUser = useStoreState((state) => state.users.patchUserLoading)
  const patchUser = useStoreActions((actions) => actions.users.patchUser)
  const actionSuccessful = useStoreState((state) => state.users.actionSuccessful)
  const setActionSuccessful = useStoreActions((actions) => actions.users.setActionSuccessful)

  // destructure props
  const { pageName, action } = props

  // LOCAL STATE
  const [pendingForm, setPendingForm] = useState(null)
  const [enableFlexConfig, setEnableFlexConfig] = useState(
    defaultUserData?.role || defaultUserData?.access?.buildingIds.length > 0
  )
  const [flexConfigComplete, setFlexConfigComplete] = useState(true) // indicates complete flex app config data

  // update flex config completion tracking state
  useEffect(() => {
    setFlexConfigComplete(pendingForm?.access?.buildingIds?.length > 0)
  }, [pendingForm?.access?.siteIds, pendingForm?.access?.buildingIds])

  // get the proper actions for the desired user management function
  let userActions = []
  switch (action) {
    case 'add':
      userActions = addUserActions(enableFlexConfig && !flexConfigComplete, () => {
        submitHTML.current.click()
      })
      break
    case 'edit':
      userActions = editUserActions(
        enableFlexConfig && !flexConfigComplete,
        () => {
          submitHTML.current.click()
        },
        null // handleDeleteUser not implemented yet
      )
      break
  }

  /**
   * Handler for updating input field changes in the pending object.
   */
  const handleInputChange = (e) => {
    e.preventDefault()

    const target = e.target
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'number' && target.value !== ''
        ? Number(target.value)
        : (target.type === 'text' || target.type === 'tel') && target.value === ''
        ? null
        : target.value
    const name = target.name

    setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Form update handler for submitting of user data.
   */
  const handleUpdate = (e) => {
    e.preventDefault()

    const username = pendingForm?.username

    // construct the payload for auth user creation
    let authPayload = {
      firstName: pendingForm?.firstName,
      lastName: pendingForm?.lastName,
      phone: pendingForm?.phone,
    }

    // name for flex user creation
    const name =
      pendingForm?.firstName && pendingForm?.lastName ? `${pendingForm?.firstName} ${pendingForm?.lastName}` : undefined

    // only construct flex payload if flex config is enabled and access info is present
    let flexPayload =
      enableFlexConfig && flexConfigComplete
        ? {
            name: name,
            email: pendingForm?.username,
            role: pendingForm?.role,
            phone: pendingForm?.phone,
            access: pendingForm?.access
              ? {
                  siteIds: pendingForm?.access?.siteIds,
                  buildingIds: pendingForm?.access?.buildingIds,
                }
              : undefined,
            // Set notification defaults until they're editable in UI
            notifications: {
              email: true,
              phone: false,
            },
          }
        : undefined

    if (action === 'add') {
      // new user
      authPayload.partnerIds = [currentPartner.value] // add partner id of logged-in user to payload

      createUser({ username, authPayload, flexPayload })
    } else if (action === 'edit') {
      // filter out the auth fields that didn't change
      Object.keys(authPayload).forEach((key) => authPayload[key] === defaultUserData[key] && delete authPayload[key])

      // only provide the flex payload access object if the values have changed
      if (flexPayload) {
        flexPayload.access = Object.entries(flexPayload.access).reduce((acc, [key, value]) => {
          if (
            value?.length !== defaultUserData.access?.[key].length ||
            !defaultUserData.access?.[key].every((x) => value.includes(x))
          ) {
            if (acc == undefined) acc = {}
            acc[key] = value
          }

          return acc
        }, undefined)
      }

      // update the existing user
      patchUser({ username, authPayload, flexPayload, flexUserId: defaultUserData?.flexUserId })
    }
  }

  useEffect(() => {
    if (action === 'edit' && defaultUserData) {
      setPendingForm(defaultUserData)
    } else {
      setPendingForm(null)
    }
  }, [action, defaultUserData])

  // any form action
  useEffect(() => {
    let loading = creatingUser || updatingUser

    setFormLoading(loading)

    // if the action was successful, navigate back to the previous page
    if (actionSuccessful) {
      setActionSuccessful(false)

      // give time delay for toast success message to be seen then navigate back
      setTimeout(() => {
        navigate(-1)
      }, 1500)
    }
  }, [setFormLoading, creatingUser, updatingUser, actionSuccessful, setActionSuccessful])

  return (
    <div>
      <PageHeader pageName={pageName} userActions={userActions} />
      <div className="App-module" style={{ padding: '0px', alignItems: 'center', overflow: 'auto' }}>
        <form onSubmit={handleUpdate}>
          {formLoading && <FontAwesomeIcon pulse icon={faSpinner} className="loading" />}
          <PersonalInformationCard
            groupName="Personal Information"
            initial={defaultUserData}
            pendingForm={pendingForm}
            onChange={handleInputChange}
            action={action}
          />
          <FlexAppConfigCard
            groupName="Flex App"
            initial={defaultUserData}
            pendingForm={pendingForm}
            setPendingForm={setPendingForm}
            onChange={handleInputChange}
            enableFlexConfig={enableFlexConfig}
            setEnableFlexConfig={setEnableFlexConfig}
          />
          <input ref={submitHTML} type="submit" style={{ display: 'none' }} />
        </form>
      </div>
    </div>
  )
}

export default UserManagement
